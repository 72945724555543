import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private locationn: Location) { }

  ngOnInit() {
  }
  goBack(): void{
    this.locationn.back();
  }
}
