import { MasterDashboardModule } from './mastersetting/master-dashboard.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DeleteUserComponent } from './delete-user/delete-user.component';



const routes: Routes = [
  { path: '', redirectTo: 'user/login', pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'delete-user', component: DeleteUserComponent },
  { path: 'user', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule) },
  { path: 'app', loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule) },
  { path: 'vertexadmin', loadChildren: () => import('./vertexadmin/vertexadmin.module').then(m => m.VertexadminModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
