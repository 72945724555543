import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/_service/app.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-visitorlogin',
  templateUrl: './visitorlogin.component.html',
  styleUrls: ['./visitorlogin.component.scss']
})
export class VisitorloginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  loginFormErrors: any;
  message: any;
  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private route: Router) {
    this.loginFormErrors = {
      EmailId: {},
      Password: {}
    };
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      EmailId: ['', [Validators.required, Validators.email]],
      Password: ['', Validators.required],
    });
    this.loginForm.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged();
    });
  }
  onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      this.loginFormErrors[field] = {};

      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }
  get f() { return this.loginForm.controls; }

  doLogin() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.submitted = false;
      this.appService.login({ ...this.loginForm.value, GrantType: 'password' }, 'Login/Tokens')
        .subscribe(response => {
          if (response.body.Success) {
            localStorage.setItem('office_q_uid', JSON.stringify(response.body.Result));
            this.route.navigate(['visitor/add-visitor']);
          } else {
            this.message = response.body.Result.TransactionMessage.Message;
          }
        }),err => {
          console.log(err.message);
        }, () => {
          console.log('completed');
        }
    }
  }
}

