import {
	Component,
	ElementRef,
	ViewChild,
	AfterViewInit,
	Renderer2
} from '@angular/core';
import {
	Location
} from '@angular/common';
import {
	MatDialogRef,
	MatDialog
} from '@angular/material/dialog';

@Component({
  selector: 'app-visitorrequest',
  templateUrl: './visitorrequest.component.html',
  styleUrls: ['./visitorrequest.component.scss']
})
export class VisitorrequestComponent implements AfterViewInit {
  isActive = '';
	showUploadPhoto = true;
	cameraImageUpload: boolean = false;
	cameraImage: string | ArrayBuffer = null;
	imageSrc: string | ArrayBuffer | null = null;
	stream: MediaStream | null = null;
	captures: string[] = []; // Array to store captured images
	visitorEntry = {
		ImageFileName: ''
	}; // Your model

	// ViewChild references for video and canvas elements
	@ViewChild('video', {
		static: false
	}) public video: ElementRef < HTMLVideoElement > ;
	@ViewChild('canvas', {
		static: false
	}) public canvas: ElementRef < HTMLCanvasElement > ;


	constructor(
		public dialog: MatDialog,
		private locationn: Location,
		private renderer: Renderer2
	) {}

	ngOnInit() {
		// Additional initialization logic can go here
    this.isActive = 'Constituency';
    if(document.getElementById('div_UploadPhoto')){
		this.renderer.setStyle(document.getElementById('div_UploadPhoto'), 'display', 'block');
		this.playcamera();
    }

    if(document.getElementById('div_CapturePhoto')){
		this.renderer.setStyle(document.getElementById('div_CapturePhoto'), 'display', 'none');
		this.playcamera();
    }
	}




	playcamera() {
		this.captures = null;
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({
				video: true
			}).then(stream => {
				this.stream = stream; // Save the stream to stop it later
				this.video.nativeElement.srcObject = stream;
				this.video.nativeElement.play();
				
			}).catch(error => {
				console.error('Error accessing the camera:', error);
			});
		}	
	}
	
	stopCamera() {
		if (this.stream) {
			// Stop all tracks to ensure the camera turns off
			this.stream.getTracks().forEach(track => {
				track.stop();
			});
			this.video.nativeElement.srcObject = null; // Release the video source
			this.stream = null; // Clear the stream reference
		}
	}


	ngAfterViewInit() {
		// this.playcamera()
		// if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
		// 	navigator.mediaDevices.getUserMedia({
		// 		video: true
		// 	}).then(stream => {
		// 		//  this.video.nativeElement.src = window.URL.createObjectURL(stream);
		// 		this.video.nativeElement.srcObject = stream;
		// 		this.video.nativeElement.play();
		// 	});
		// }
	}

	showHideUploadImage() {
		this.renderer.setStyle(document.getElementById('div_UploadPhoto'), 'display', 'none');
		this.renderer.setStyle(document.getElementById('div_CapturePhoto'), 'display', 'block');
	}

	HideUploadImage() {
		this.renderer.setStyle(document.getElementById('div_UploadPhoto'), 'display', 'block');
		this.renderer.setStyle(document.getElementById('div_CapturePhoto'), 'display', 'none');

	}

	toggleUploadMode() {
		this.showUploadPhoto = !this.showUploadPhoto;
	}

	toggleCameraUpload(enable: boolean = true) {
		this.cameraImageUpload = enable;
		this.showUploadPhoto = !enable; // Hide upload photo when in camera mode
	}


	capture() {

		const context = this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 200, 200);

		this.captures = [];

		this.visitorEntry.ImageFileName = this.canvas.nativeElement.toDataURL('image/jpg');
		this.captures.push(this.canvas.nativeElement.toDataURL('image/jpg'));
		this.visitorEntry.ImageFileName = this.makeid(length) + '.png';
	}
	makeid(length) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
	onFileChange(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input.files && input.files.length) {
			const file = input.files[0];
			const reader = new FileReader();
			reader.onload = (e: ProgressEvent) => {
				this.cameraImage = (e.target as FileReader).result as string;
			};
			reader.readAsDataURL(file);
		}
	}

	goBack(): void {
		this.locationn.back();
	}

	onFileSelected(event: Event) {
		const file = (event.target as HTMLInputElement).files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				this.imageSrc = reader.result as string; // Preview the uploaded image
				this.visitorEntry.ImageFileName = file.name; // Store the file name
			};
			reader.readAsDataURL(file);
		}
	}
}