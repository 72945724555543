import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dialogeboxes',
  templateUrl: './dialogeboxes.component.html',
  styleUrls: ['./dialogeboxes.component.scss']
})
export class DialogeboxesComponent implements OnInit {
  dialogData: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogeboxesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public formBuilder: FormBuilder,

  ) 
  
  { 
    console.log("data",data);
    this.dialogData = data;
  
}
   
    // Method to close the dialog
    onClose(): void {
      this.dialogRef.close(); // Close the dialog without any result
    }
  
    submit() {
      // Perform any actions here (e.g., save the new project)
      this.dialogRef.close(true); // Pass `true` to indicate success
    }
  

  ngOnInit() {
  }
  adddepartmentpopup(){  
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
     // width: '800px',
      //height: 'auto',
     // panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'AddDepartmentPopupModel', title: 'User Registration', data: null },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }

  addDesignationpopup(){  
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
     // width: '800px',
      //height: 'auto',
     // panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'AddDesignationPopupModel', title: 'User Registration', data: null },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }

}
