import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Location } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  isActive = 'listView';
  dialogRef: any;
  constructor(public dialog: MatDialog,  private locationn: Location) 
  { }

  ngOnInit() {

  }

    goBack(): void{
      this.locationn.back();
    }

}
