import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/_service/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import { DialogeboxesComponent } from 'src/@common/dialogeboxes/dialogeboxes.component';
@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  public configure: PerfectScrollbarConfigInterface = {};
  userList: any[] = [];
  deptList:any[] = [];
  listData: any[] = [];
  searchText;
  selectedUser: any[] = [];
  userIds: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<UserModalComponent>,
    private appService: AppService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.selectedUser = data.userData;
    if (data.userData.length > 0) {
      data.userData.forEach(element => {
        this.userIds.push(element.UserId);
      });
    }
  }

  ngOnInit() {
    this.detailList(null);
    this.getDepartment();
  }

  openAddContactDialog(){  
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
      width: '800px',
      height: 'auto',
      panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'AddNewContact', title: 'User Registration', data: null },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }



  detailList(dept:any) {
    this.appService.postService({}, 'CommonUserList')
      .subscribe(response => {
        this.userList = response.body.Result;
        if(dept!=null){
          this.userList = this.userList.filter(x=> x.Department.DepartmentId==dept.Id)
        }
        const a = this.userList.sort((a, b) => {
          if (a.FirstName < b.FirstName) { return -1; }
          if (a.FirstName > b.FirstName) { return 1; }
          return 0;
        })
      });
  }

  addUser() {
    this.dialogRef.close(this.selectedUser);
  }

  getDepartment(){
    this.appService.postService({}, 'DepartmentList')
      .subscribe(response => {
        this.deptList = response.body.Result;
      });
  }

  selectUser(value: any, user: any, id: any) {
    if (value.checked) {
      console.log(user)
      this.selectedUser.push(user);
      console.log(this.selectUser)
    }
    else {
      const removeIndex = this.selectedUser.findIndex(item => item.UserId === id);
      if (removeIndex !== -1) {
        this.selectedUser.splice(removeIndex, 1);
      }

    }
  }

  deleteiocTo(i: number) {
    this.selectedUser.splice(i, 1);
  }

}
