import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  baseUrl = environment.baseUrl;
  base2Url = environment.base2Url;
  loginUrl = environment.loginUrl;
  isPrinting = false;
  constructor(private http: HttpClient, private router: Router) { }

  postService(data: any, url: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('office_q_uid'));
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.s_token
    });
    return this.http.post(this.baseUrl + url, data, {
      headers: httpHeaders,
      observe: 'response'
    });

  }

  post2Service(data: any, url: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('office_q_uid'));
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.s_token
    });
    return this.http.post(this.base2Url + url, data, {
      headers: httpHeaders,
      observe: 'response'
    });

  }

  login(user: any, url: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.loginUrl + url, user,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  fileService(data: any, url: any): Observable<any>{
    const user = JSON.parse(localStorage.getItem('office_q_uid'));

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'undefined',
      Authorization: 'Bearer ' + user.s_token
    });
    return this.http.post(this.baseUrl + url, data,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    fileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
 }

 public exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}

printDocument(documentName: string, documentData: string[]) {
  this.isPrinting = true;
  this.router.navigate(['/',
    { outlets: {
      'print': ['print', documentName, documentData.join()]
    }}]);
}

onDataReady() {
  setTimeout(() => {
    window.print();
    this.isPrinting = false;
    this.router.navigate([{ outlets: { print: null }}]);
  });
}

}
