import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AppService } from 'src/app/_service/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-printpagesettings',
  templateUrl: './printpagesettings.component.html',
  styleUrls: ['./printpagesettings.component.scss']
})
export class PrintpagesettingsComponent implements OnInit {
  pageSetting:any = {
    Id: 1,
    A4PageEnglishFontSize:"0",
    A4PageHindiFontSize:"0",
    A4PagePageWidth: "0",
    A4PagePageHeight: "0",
    A4PagePageMarginTop: "0",
    A4PagePageMarginRight: "0",
    A4PagePageMarginBottom: "0",
    A4PagePageMarginLeft: "0",
    A5PageEnglishFontSize: "0",
    A5PageHindiFontSize: "0",
    A5PagePageWidth: "0",
    A5PagePageHeight: "0",
    A5PagePageMarginTop: "0",
    A5PagePageMarginRight: "0",
    A5PagePageMarginBottom: "0",
    A5PagePageMarginLeft: "0",
    ActiveStatus: true,
    IsApproved: "Approve",
    Status: "1",
    CreatedBy:{
      CreatedById:0,
      CreatedByName:"",
      CreatedIpAddress:"",
      CreatedDate: new Date()
    },
    UpdatedBy:{
      CreatedById:0,
      CreatedByName:"",
      CreatedIpAddress:"",
      CreatedDate: ""
    },
    AccessInfo:{
    }
  }

  @ViewChild('f', { static: false }) form: any;

  constructor(public dialog: MatDialog,
    private locationn: Location,private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    ) { 
      const data = JSON.parse(localStorage.getItem('office_q_uid'));
      this.pageSetting.CreatedBy.CreatedById = data.UserId;
      this.pageSetting.AccessInfo.UserId = data.UserId;
      this.pageSetting.AccessInfo.UserLocationId = data.UserLocationId;
      this.pageSetting.AccessInfo.CompanyId = data.CompanyId;
    }

  ngOnInit() {
    this.getPageSetting();
  }


  getPageSetting = () => {
    this.spinner.show();
    //this.route.queryParams.subscribe(params => {
      // if (params.Id) {
      //   this.pageSetting.Id = params.Id
        this.appService.post2Service({ ...this.pageSetting }, 'PageSettings/getPageSetting')
          .subscribe(response => {
            this.pageSetting = response.body.Result;
            // if (this.meeting.ModuleModel) {
            //   this.isModule = this.meeting.ModuleModel.ModuleId;
            //   this.isAttached = true;
            // }
            this.spinner.hide();

          });
     // }
   // })

  }

  savePageSetting(){
    this.spinner.show();
    if (this.form.valid) {
      this.appService.post2Service({ ...this.pageSetting}, 'PageSettings/Save')
        .subscribe(response => {
          if (response.body.Success) {
            this.router.navigate(['/app/dashboard/master']);
            //this.meetingList();
          }
        });

    }
    this.spinner.hide();
  }

cancelPageSetting(){
  this.router.navigate(['/app/dashboard/master']);
}

  goBack(): void{
    this.locationn.back();
  }

}