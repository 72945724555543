
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Location } from '@angular/common';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
  isActive = 'listView';
  dialogRef: any;
  showSearch: boolean;
  advanceSearch:boolean;
  showDuration: boolean = true;
  constructor(public dialog: MatDialog,
    private locationn: Location
    ) {
    }

  ngOnInit() {


  }

    goBack(): void{
      this.locationn.back();
    }
    downloadImage(imageSrc, imageName) {
      const link = document.createElement('a');
      link.download = imageName;
      link.href = imageSrc;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

}
