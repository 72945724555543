import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MeetingModalComponent } from 'src/app/meetings/meeting-modal/meeting-modal.component';
import { SwiperComponent } from 'angular2-useful-swiper';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/_service/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ConfirmModalComponent } from 'src/@common/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-attached-modal',
  templateUrl: './attached-modal.component.html',
  styleUrls: ['./attached-modal.component.scss']
})
export class AttachedModalComponent implements OnInit {
  public configure: PerfectScrollbarConfigInterface = {};
  @ViewChild('usefulSwiper', { static: false }) usefulSwiper: SwiperComponent;
  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30,
    initialSlide: 0,
  };
  confirmDialog: MatDialogRef<ConfirmModalComponent>;
  attachedList: any[] = [];
  attachedDetail:any;
  moduleId:number;
  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<MeetingModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.attachedList = data.detailList;
    this.config.initialSlide = data.activeIndex;
    this.moduleId = data.module;
  }

  ngOnInit() {
    this.attachedDetail = this.config.initialSlide;
  }

  // getProgramDashList() {
  //   this.spinner.show();
  //   this.appService.postService({}, 'ProgramList')
  //     .subscribe(response => {
  //       this.programList = response.body.Result;
  //       this.spinner.hide();
  //     });
  // }
  // next = () => {
  //   this.usefulSwiper.swiper.slideNext();
  // }
  // prev = () => {
  //   this.usefulSwiper.swiper.slidePrev();
  // }
  closeModal = () => {
    this.dialogRef.close(this.attachedList);
  }


  // editModal = (id) => {
  //   this.router.navigate(['/app/dashboard/programe/add-program'], { queryParams: { Id: id } });
  //   this.dialogRef.close();
  // }

  // delete(id) {
  //   this.confirmDialog = this.dialog.open(ConfirmModalComponent, {
  //     autoFocus: false,
  //     panelClass: 'modal-xs',
  //     disableClose: true,
  //     data: { action: 'delete', title: 'Are you sure you want to delete this program?' }
  //   });
  //   this.confirmDialog.afterClosed()
  //   .subscribe(result => {
  //     if (!result) {
  //       return;
  //     } else {
  //   this.appService.postService({ Id: id }, 'ProgramDelete')
  //     .subscribe(response => {
  //       if (response.body.Result) {
  //         //this.getProgramDashList();
  //       }
  //     });
  //   }
  // });
  // }

}
